import { FunctionComponent, useState } from 'react';
import { Path, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import TickIcon from '../../../assets/icon-components/TickIcon';
import { ILoginFormValues } from '../../Login/helpers/ILoginFormValues';
import './CustomCheckbox.scss';

interface CustomCheckboxProps {
  defaultValue: boolean;
  label: Path<ILoginFormValues>;
  register: UseFormRegister<ILoginFormValues>;
  required: boolean;
  setValue: UseFormSetValue<ILoginFormValues>;
  errorState?: boolean;
}

const CustomCheckbox: FunctionComponent<CustomCheckboxProps> = ({
  defaultValue,
  label,
  register,
  required,
  setValue,
  errorState
}) => {
  const [isChecked, setIsChecked] = useState(defaultValue);

  const onClickHandler = () => {
    setValue(label, !isChecked);
    setIsChecked((prevChecked) => {
      return !prevChecked;
    });
  };

  return (
    <div className="Checkbox">
      <div
        className={`Checkbox__wrapper${!isChecked && errorState ? 'Error' : ''}`}
        onClick={onClickHandler}>
        <div className={`Checkbox__iconWrapper${isChecked ? 'Show' : ''}`}>
          <TickIcon />
        </div>
      </div>
      <input
        className="Checkbox__hiddenCheckbox"
        type="checkbox"
        checked={isChecked}
        {...register(label, { required })}
      />
    </div>
  );
};

export default CustomCheckbox;
