import { Container } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LoginContext } from '../../App';
import { deleteUser } from '../../services/auth.service';
import CustomModal from '../CustomModal/CustomModal';
import { CustomModalType } from '../CustomModal/helpers/CustomModalType';
import DeleteAccountModal from '../Modals/DeleteAccountModal/DeleteAccountModal';
import { SnackbarContext } from '../SnackbarAlert/SnackbarAlert';
import './Footer.scss';

const Footer = () => {
  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);
  const { setIsLoggedIn } = useContext(LoginContext);
  const [isDeleteAccountModalVisible, setIsDeleteAccountModalVisible] = useState(false);
  const emailAddress = 'mailto:' + process.env.REACT_APP_ERROR_REPORT_EMAIL;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalConfig, setModalConfig] = useState({ type: CustomModalType.PDF, source: {} });

  const handleDelete = async () => {
    try {
      await deleteUser();
      setSnack({
        isError: false,
        open: true,
        message: t('modals.deleteUserSuccess')
      });

      localStorage.removeItem('accessToken');
      localStorage.removeItem('expiresAt');
      setIsLoggedIn(false);
    } catch (error) {
      setSnack({
        isError: true,
        open: true,
        message: t('modals.deleteUserFailed')
      });
    }
  };

  const htmlModalHandler = () => {
    const htmlContent = t('impressum.htmlContent');
    setModalConfig({ type: CustomModalType.HTML, source: { htmlContent } });
    setIsModalVisible(true);
  };

  const closeModalHandler = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="footer-container">
      <Container>
        <div className="footer-content">
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <a className="link" href={emailAddress}>
                <div>{t('footer.bugReport')}</div>
              </a>
            </Grid>
            <Grid item>
              <a className="link" onClick={htmlModalHandler}>
                <div>{t('impressum.title')}</div>
              </a>
            </Grid>
            <Grid item>
              <Link className="link" to={'/privacy-notice'}>
                {t('footer.privacyNotice')}
              </Link>
              <div className="delete-account" onClick={() => setIsDeleteAccountModalVisible(true)}>
                {t('footer.deleteAccount')}
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
      <DeleteAccountModal
        isVisible={isDeleteAccountModalVisible}
        closeModalHandler={() => setIsDeleteAccountModalVisible(false)}
        confirmModalHandler={handleDelete}
      />
      <CustomModal
        isVisible={isModalVisible}
        closeModalHandler={closeModalHandler}
        modalConfig={modalConfig}
      />
    </div>
  );
};

export default Footer;
