import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LoginContext } from '../../../App';
import { revokeToken } from '../../../services/auth.service';

interface DrawerMenuItemProps {
  label: string;
  linkTo: string;
  isLogout: boolean;
  isExternal: boolean;
  handleDrawerItemClick: () => void;
}

const DrawerMenuItem = ({
  label,
  linkTo,
  isLogout,
  isExternal,
  handleDrawerItemClick
}: DrawerMenuItemProps) => {
  const { setIsLoggedIn } = useContext(LoginContext);
  const urlToOpen = process.env.REACT_APP_EMPLOYEE_PORTAL_URL;
  const { t } = useTranslation();

  const handleLogout = () => {
    revokeToken();
    localStorage.removeItem('accessToken');
    localStorage.removeItem('expiresAt');
    setIsLoggedIn(false);
  };

  const handleHrPortal = () => {
    window.open(urlToOpen);
  };

  return (
    <>
      {isLogout && (
        <Link to={linkTo} className="drawer-item" onClick={handleLogout}>
          {label}
        </Link>
      )}

      {isExternal && (
        <Link to={linkTo} className="drawer-item" onClick={handleHrPortal}>
          {label}
        </Link>
      )}

      {!isExternal && !isLogout && (
        <Link to={linkTo} className="drawer-item" onClick={handleDrawerItemClick}>
          {label}
        </Link>
      )}
    </>
  );
};

export default DrawerMenuItem;
