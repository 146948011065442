import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PdfViewer from '../../components/PdfViewer/PdfViewer';
import FileType from '../../enums/file-type.enum';
import { getFile } from '../../services/files.service';
import './PrivacyNoticePage.scss';

const PrivacyNoticePage = () => {
  const [pdfUri, setPdfUri] = useState<string | Blob>('');

  const { t } = useTranslation();

  const getData = async () => {
    const file = await getFile(FileType.DATA_POLICY);

    setPdfUri((previousValue) => {
      const content = new Uint8Array(file);
      const blob = new Blob([content], { type: 'application/pdf' });
      return blob;
    });
    return file;
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="privacy-notice-page">
      <h2 className="font-audi-extended-bold header-text">
        {t('privacyNoticePage.privacyNotice')}
      </h2>
      <div className="pdf-container">
        <PdfViewer pdfUri={pdfUri} />
      </div>
    </div>
  );
};

export default PrivacyNoticePage;
