import TextField from '@mui/material/TextField/TextField';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Path, UseFormRegister } from 'react-hook-form';
import { ILoginFormValues } from '../../Login/helpers/ILoginFormValues';
import './CustomInput.scss';

interface CustomInputProps {
  value: string;
  register: UseFormRegister<ILoginFormValues>;
  required?: boolean;
  onChange?: (inputValue: string) => void;
  placeholderText: string;
  errorState: boolean;
  maxLength?: number;
  acceptNumbersOnly?: boolean;
  label: Path<ILoginFormValues>;
  type?: 'text' | 'password';
}

const CustomInput: FunctionComponent<CustomInputProps> = ({
  value,
  register,
  required,
  errorState,
  maxLength,
  onChange,
  placeholderText,
  acceptNumbersOnly,
  label,
  type
}) => {
  const [currentValue, setCurrentValue] = useState(value);
  const [hasInput, setHasInput] = useState(false);

  const containsNumber = (str: string) => {
    return /\d/.test(str);
  };

  const valueChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (acceptNumbersOnly) {
      if (containsNumber(e.target.value)) {
        e.target.value = e.target.value.toString().slice(0, 12);
      } else {
        e.target.value = '';
      }
      if (maxLength) {
        if (e.target.value.length > maxLength) {
          e.target.value = e.target.value.slice(0, maxLength);
        }
      }
      if (e.target.value === '') {
        setHasInput(false);
      } else {
        setHasInput(true);
      }
      setCurrentValue(e.target.value);
    }
  };

  useEffect(() => {
    onChange && onChange(currentValue);
  }, [currentValue]);

  return (
    <div className="field-wrapper">
      <TextField
        className="custom-text-field"
        value={currentValue}
        error={errorState && !hasInput}
        label={placeholderText}
        variant="standard"
        type={type ? type : 'text'}
        {...register(label, { required })}
        onChange={valueChangeHandler}
      />
    </div>
  );
};
export default CustomInput;
