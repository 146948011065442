import { Skeleton } from '@mui/material';
import { FunctionComponent, memo, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useTranslation } from 'react-i18next';
import './PdfViewer.scss';

interface PdfViewerProps {
  pdfUri: string | Blob;
}

const PdfViewer: FunctionComponent<PdfViewerProps> = memo(({ pdfUri }) => {
  const [pageNumber, setPageNumber] = useState(1);
  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [numPages, setNumPages] = useState<number | null>(null);
  const { t } = useTranslation();

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () =>
    setPageNumber((prevPageNumber) => (prevPageNumber - 1 <= 1 ? 1 : prevPageNumber - 1));

  const goToNextPage = () =>
    setPageNumber((prevPageNumber) =>
      prevPageNumber + 1 >= numPages! ? numPages! : prevPageNumber + 1
    );

  return (
    <div>
      <Document
        className="PdfViewer__PDFWrapper"
        file={pdfUri}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={<Skeleton variant="rounded" height={400} />}>
        <Page pageNumber={pageNumber} className="PdfViewer_PDF" />
      </Document>

      <nav className="pdf-nav">
        <div className="pdf-btn-container">
          <button className="pdf-page-btn" onClick={goToPrevPage}>
            {t('pdf.previousPage')}
          </button>
          <button className="pdf-page-btn" onClick={goToNextPage}>
            {t('pdf.nextPage')}
          </button>
        </div>
        <p>
          {t('pdf.page')} {pageNumber} / {numPages}
        </p>
      </nav>
    </div>
  );
});

export default memo(PdfViewer);
