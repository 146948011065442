export const menuItemTypes = {
  home: 'home',
  administrators: 'administrators',
  menu: 'menu',
  privacyNotice: 'privacyNotice',
  termsOfUse: 'termsOfUse',
  logout: 'logout',
  external: 'external'
};

export const menuItems = [
  {
    label: 'header.home',
    linkTo: '/home',
    type: menuItemTypes.home
  },
  {
    label: 'header.termsOfUse',
    linkTo: '/user-guide',
    type: menuItemTypes.termsOfUse
  },
  {
    label: 'header.hrPortal',
    linkTo: '#',
    type: menuItemTypes.external
  },
  {
    label: 'header.logout',
    linkTo: '#',
    type: menuItemTypes.logout
  }
];
