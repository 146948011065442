import { Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PdfViewer from '../../components/PdfViewer/PdfViewer';
import FileType from '../../enums/file-type.enum';
import { getFile } from '../../services/files.service';
import './TermsOfUsePage.scss';

const TermsOfUsePage = () => {
  const [pdfUri, setPdfUri] = useState<string | Blob>('');
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const getData = async () => {
    setIsLoading(true);
    const file = await getFile(FileType.TERMS_AND_CONDITIONS);

    setPdfUri(() => {
      const content = new Uint8Array(file);
      const blob = new Blob([content], { type: 'application/pdf' });
      return blob;
    });
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="terms-of-use-page">
      <h2 className="font-audi-extended-bold header-text">{t('termsOfUsePage.termsOfUse')}</h2>
      <div className="pdf-container">
        {isLoading ? <Skeleton variant="rounded" height={400} /> : <PdfViewer pdfUri={pdfUri} />}
      </div>
    </div>
  );
};

export default TermsOfUsePage;
