import { Grid } from '@mui/material';
import { FunctionComponent, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FakeInputNDigits from '../../FakeInputNDigits/FakeInputNDigits';
import CustomButton from '../../UI/CustomButton/CustomButton';
import Modal from '../../UI/Modal/Modal';
import './CustomPriceModal.scss';

interface CustomModalProps {
  isVisible: boolean;
  closeModalHandler: (topUpBy?: number | 'custom' | null) => void;
}

const CustomPriceModal: FunctionComponent<CustomModalProps> = memo(
  ({ isVisible, closeModalHandler }) => {
    const { t } = useTranslation();
    const [value, setValue] = useState<number | null>(null);
    const min = 0;
    const max = 50000;

    const handleConfirm = () => {
      if (value) {
        closeModalHandler(value);
        setValue(null);
      }
    };

    const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
      const value = Math.max(min, Math.min(max, Number(event.currentTarget.value)));
      setValue(value);
    };

    return (
      <Modal
        isVisible={isVisible}
        closeModalHandler={closeModalHandler}
        customClass="custom-price-modal">
        <Grid container direction="column" spacing={2} className="custom-price-modal-content">
          <Grid item className="modal-text-container">
            <div className="font-audi-extended-bold">
              <h3>{t('modals.customPriceOptionTitle')}</h3>
            </div>
          </Grid>

          <div>
            <div>
              <FakeInputNDigits value={value ? value.toString() : ''} digits={5} dotTo={[2]} />
            </div>
            <input className="hidden-input" maxLength={5} autoFocus onChange={handleChange} />

            <div>{t('modals.customPriceOptionNotice')}</div>
            <CustomButton customFunction={handleConfirm} text={t('modals.uploadButton')} />
          </div>
        </Grid>
      </Modal>
    );
  }
);

export default memo(CustomPriceModal);
