import { ApiClient } from '../apiClient/apiClient';

const api = ApiClient.getInstance();

const startSimplepayTransaction = async (amount: number) => {
  return api.post(`simplepay/startTransaction?platformType=web&amount=${amount.toString()}`, {}).then((res) => {
    return res.data;
  }).catch((error) => {
    return error.response.data
  });
}

export { startSimplepayTransaction };
