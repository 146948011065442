/* eslint-disable import/no-anonymous-default-export */
export default {
  login: {
    loginHeader: 'Audi Gastro',
    loginText: 'Bejelentkezés',
    rememberPersonalData: {
      title: 'Belépési adataim megjegyzése',
      htmlContent:
        '<div class="font-audi-normal" style="padding: 0 20px"><p>A jelölőnégyzet bepipálásával – sikeres belépést követően – az adott eszközön és böngészőben 14 napig bejelentkezve maradsz, így nem szükséges újra bejelentkezned, mindaddig míg a kijelentkezés gombra nem kattintasz!</p></div>'
    },
    sessionExpired: 'Munkamenet lejárt, kérjük jelentkezzen be újra!',
    gdpr: {
      text1: 'Az',
      gdprText: 'Adatvédelmi Tájékoztatót',
      text2: ' és a',
      eulaText: 'Felhasználási útmutatót',
      text3: 'elolvastam, azok tartalmát tudomásul vettem.'
    },
    eula: {
      text1: 'Hozzájárulok az',
      gdprText: 'Adatvédelmi Tájékoztató',
      text2: 'ban az egyenlegfeltöltéssel összefüggő személyes adataim kezeléséhez.'
    },
    loginButton: 'Belépés'
  },
  impressum: {
    title: 'Impresszum',
    htmlContent:
      '<div class="font-audi-normal" style="padding: 0 20px"><h1 class="font-audi-extended-bold">AUDI HUNGARIA Zártkörűen Működő Részvénytársaság</h1><p>9027 Győr, Audi Hungária út 1.</p><p><strong>mailto:</strong> <a href="mailto:ah@audi.hu">ah@audi.hu</a></p><p><a href="https://www.audi.hu/" rel="noopener noreferrer" target="_blank">https://www.audi.hu/</a></p><p><strong>Cégjegyzékszám:</strong> 08-10-001840</p><p><strong>Nyilvántartó hatóság:</strong>Győri Törvényszék Cégbírósága</p><p><strong>Adószám:</strong> 23391475-2-08</p></div>'
  },
  openSource: 'OpenSource Licensz',
  loginInputs: {
    personCode: 'Törzsszám',
    cardCode: 'Kártyaszám',
    pinCode: 'PIN'
  },
  header: {
    home: 'Főoldal',
    hrPortal: 'HR portál',
    termsOfUse: 'Felhasználási útmutató',
    logout: 'Kijelentkezés',
    closeText: 'Bezár',
    openText: 'Menu'
  },
  footer: {
    deleteAccount: 'Fiókom törlése',
    bugReport: 'Hiba bejelentő',
    privacyNotice: 'Adatvédelmi tájékoztató'
  },
  modals: {
    deleteUserSuccess: 'Fiók törlése sikeres!',
    deleteUserFailed: 'Fiók törlése sikertelen! Próbálja újra később.',
    deleteAccountConfirmModalHeaderText: 'Biztosan törölni kívánja a fiókját?',
    customPrice: 'Egyedi összeg',
    priceOptionsTitle: 'Egyenleg feltöltés',
    customPriceOptionTitle: 'Kérjük adja meg a feltölteni kívánt összeget:',
    customPriceOptionNotice: 'Maximum feltölthető összeg: 50 000 Ft',
    priceUploadConfirmTitle: 'Megerősíti a feltöltést?',
    priceUploadConfirmText: 'Feltölteni kívánt összeg:',
    uploadButton: 'Feltöltés',
    confirmButton: 'Megerősítés',
    pendingTransactionHeader: 'A tranzakció feldolgozása folyamatban van',
    pendingTransactionText:
      'Amennyiben a tranzakció sikeresen megtörtént, az összeg 1 percen belül jóváírásra kerül.',
    successfulTransactionHeader: 'Sikeres fizetés, a tranzakció feldolgozása folyamatban van',
    successfulTransactionText:
      'Amennyiben a feldolgozás sikeres, az összeg 1 percen belül jóváírásra kerül.',
    failedTransactionHeader: 'Sikertelen fizetés, kérjük próbálja újra!',
    failedTransactionTipText:
      'Kérjük, ellenőrizze a tranzakció során megadott adatok helyességét. Amennyiben minden adatot helyesen adott meg, a visszautasítás okának kivizsgálása érdekében kérjük, szíveskedjen kapcsolatba lépni kártyakibocsátó bankjával.',
    cancelledTransactionHeader: 'Ön megszakította a fizetést.',
    simplePayTransactionId: 'SimplePay tranzakció azonosító: ',
    timeoutTransactionText: 'Ön túllépte a tranzakció elindításának lehetséges maximális idejét.'
  },
  home: {
    balanceText: 'Aktuális egyenleg',
    uploadBalance: 'Egyenleg feltöltés',
    transactionHistory: 'Tranzakció történet',
    menu: 'Étlap'
  },
  common: {
    yes: 'Igen',
    no: 'Nem',
    huf: 'Ft'
  },
  transactionHistoryPage: {
    title: 'Tranzakciós történet',
    filterAll: 'Összes',
    filterExpense: 'Vásárlások',
    filterIncome: 'Feltöltések',
    noResult: 'Nincs megjeleníthető találat!',
    vending: 'Vásárlás',
    deposit: 'Feltöltés'
  },
  menuPage: {
    menu: 'Étlap'
  },
  privacyNoticePage: {
    privacyNotice: 'Adatvédelmi tájékoztató'
  },
  termsOfUsePage: {
    termsOfUse: 'Felhasználási útmutató'
  },
  file: {
    fileNotFound: 'A fájl nem található'
  },
  pdf: {
    previousPage: 'Előző',
    nextPage: 'Következő',
    page: 'Oldal'
  }
};
