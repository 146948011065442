import { Grid } from '@mui/material';
import { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../UI/CustomButton/CustomButton';
import Modal from '../../UI/Modal/Modal';
import './PriceOptionConfirmModal.scss';

interface CustomModalProps {
  isVisible: boolean;
  closeModalHandler: (confirm?: boolean) => void;
  topUpBy: number | null;
}

const PriceOptionConfirmModal: FunctionComponent<CustomModalProps> = ({
  isVisible,
  closeModalHandler,
  topUpBy
}) => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    closeModalHandler(true);
  };

  return (
    <Modal
      isVisible={isVisible}
      closeModalHandler={closeModalHandler}
      customClass="price-option-confirm-modal">
      <Grid container direction="column" spacing={2} className="price-options-modal-content">
        <Grid item className="modal-text-container">
          <div className="font-audi-extended-bold">
            <h3>{t('modals.priceUploadConfirmTitle')}</h3>
          </div>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} md={8}>
            <Grid>
              {t('modals.priceUploadConfirmText')}
              <h3>
                {new Intl.NumberFormat('hu-HU', {
                  style: 'currency',
                  currency: 'HUF',
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0
                }).format(Number(topUpBy))}
              </h3>
            </Grid>
            <CustomButton
              text={t('modals.confirmButton')}
              customFunction={handleConfirm}></CustomButton>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default memo(PriceOptionConfirmModal);
