enum HtmlContentType {
  // Translatables
  IMPRESSUM = 'impressum.htmlContent',
  REMEMBER_PERSONAL_DATA = 'login.rememberPersonalData.htmlContent',

  // No Translations needed
  OPEN_SOURCE = 'utils/OpenSourceText.ts'
}

export default HtmlContentType;
