import { FunctionComponent } from 'react';
import { createPortal } from 'react-dom';
import './Backdrop.scss';

interface BackdropProps {
  isVisible: boolean;
  isClosing: boolean;
  closeModalHandler: () => void;
}

const Backdrop: FunctionComponent<BackdropProps> = ({
  isVisible,
  closeModalHandler,
  isClosing
}) => {
  return (
    <>
      {isVisible &&
        createPortal(
          <div
            className={`Backdrop${isClosing ? 'Closing' : ''}`}
            onClick={closeModalHandler}></div>,
          document.getElementById('backdrop-root')!
        )}
    </>
  );
};

export default Backdrop;
