import moment from 'moment';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoginContext } from '../../App';
import AudiLogo from '../../assets/icon-components/AudiLogo';
import InfoIcon from '../../assets/icon-components/InfoIcon';
import FileType from '../../enums/file-type.enum';
import HtmlContentType from '../../enums/html-content-type.enum';
import { ILoginRequest } from '../../interfaces/requests/auth/login.request';
import { login } from '../../services/auth.service';
import { getFile, getFileAttributes } from '../../services/files.service';
import { openSourceText } from '../../utils/OpenSourceText';
import CustomModal from '../CustomModal/CustomModal';
import { CustomModalType } from '../CustomModal/helpers/CustomModalType';
import LanguagePicker from '../LanguagePicker/LanguagePicker';
import { SnackbarContext } from '../SnackbarAlert/SnackbarAlert';
import CustomButton from '../UI/CustomButton/CustomButton';
import CustomCheckbox from '../UI/CustomCheckbox/CustomCheckbox';
import CustomInput from '../UI/CustomInput/CustomInput';
import { ILoginFormValues } from './helpers/ILoginFormValues';
import './Login.scss';

const isPDFFileType = (token: FileType | HtmlContentType): token is FileType => {
  return Object.values(FileType).includes(token as FileType);
};

const isHTMLContentType = (token: FileType | HtmlContentType): token is HtmlContentType => {
  return Object.values(HtmlContentType).includes(token as HtmlContentType);
};

const Login: FunctionComponent = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalConfig, setModalConfig] = useState({ type: '' as CustomModalType, source: {} });
  const [fileTypeToShow, setFileTypeToShow] = useState('' as FileType);
  const { setSnack } = useContext(SnackbarContext);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm<ILoginFormValues>();
  const { isLoggedIn, setIsLoggedIn } = useContext(LoginContext);
  const isLogoutInProgress = Boolean(localStorage.getItem('isLogoutInProgress'));

  const watchDataPolicyAcceptedField = watch('isEULAAccepted');
  const watchEULAAcceptedField = watch('isEULAAccepted');

  const emailAddress = 'mailto:' + process.env.REACT_APP_ERROR_REPORT_EMAIL;

  interface CustomizedState {
    from: string;
  }

  const checkForNewDataPolicy = () => {
    getFileAttributes(FileType.DATA_POLICY).then((res) => {
      if (
        localStorage?.getItem('termsAcceptedAt') !== null &&
        moment(localStorage.getItem('termsAcceptedAt')).isBefore(res.uploadedAt)
      ) {
        openModalHandler(CustomModalType.PDF, FileType.DATA_POLICY);
      }
    });
  };

  useEffect(() => {
    if (isLogoutInProgress) {
      setSnack({
        isError: true,
        open: true,
        message: t('login.sessionExpired')
      });
      localStorage.removeItem('isLogoutInProgress');
    }
    if (isLoggedIn && localStorage.getItem('accessToken')) {
      const { from } = (location.state as CustomizedState) || { from: { pathname: '/home' } };
      navigate(from, { replace: true });
    }
  });

  useEffect(() => {
    checkForNewDataPolicy();
  }, []);

  useEffect(() => {
    if (fileTypeToShow) {
      getData(fileTypeToShow).then((pdfBuffer) => {
        setModalConfig((previousValue) => {
          const content = new Uint8Array(pdfBuffer);
          const blob = new Blob([content], { type: 'application/pdf' });
          return { type: CustomModalType.PDF, source: { uri: blob } };
        });
      });
    }
  }, [fileTypeToShow]);

  const getData = async (fileType: FileType) => {
    const file = await getFile(fileType);

    return file;
  };

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    const adminLoginRequest: ILoginRequest = {
      personCode: data.personCode,
      cardCode: data.cardCode,
      pinCode: data.pinCode,
      rememberMe: data.rememberPersonalData
    };

    const loginResult = await login(adminLoginRequest);

    if (loginResult) {
      setSnack({
        isError: true,
        open: true,
        message: loginResult
      });
    } else {
      setIsLoggedIn(true);
    }
  };

  const closeModalHandler = () => {
    setIsModalVisible(false);
    setModalConfig({ type: '' as CustomModalType, source: {} });
    setFileTypeToShow('' as FileType);
  };

  const openModalHandler = (type: CustomModalType, contentType: FileType | HtmlContentType) => {
    if (type === CustomModalType.HTML && isHTMLContentType(contentType)) {
      const htmlContentString =
        contentType === HtmlContentType.OPEN_SOURCE ? openSourceText : t(contentType);
      htmlModalHandler(htmlContentString);
    }

    if (type === CustomModalType.PDF && isPDFFileType(contentType)) {
      setFileTypeToShow(contentType);
      if (modalConfig.source) {
        setIsModalVisible(true);
      }
    }
  };

  const htmlModalHandler = (htmlContent: string) => {
    setModalConfig({ type: CustomModalType.HTML, source: { htmlContent } });
    setIsModalVisible(true);
  };

  return (
    <div className="Login">
      <div className="Login__logoWrapper">
        <AudiLogo />
        <LanguagePicker />
      </div>
      <div className="Login__headerWrapper font-audi-extended-bold">
        <h1 className="Login__mainHeader">{t('login.loginHeader')}</h1>
        <h3 className="Login__subHeader">{t('login.loginText')}</h3>
      </div>
      <div className="Login__formWrapper">
        <form onSubmit={handleSubmit(onSubmit)}>
          <CustomInput
            register={register}
            required
            acceptNumbersOnly
            label="personCode"
            placeholderText={t('loginInputs.personCode')}
            errorState={errors.personCode ? true : false}
            value={''}
          />
          <CustomInput
            register={register}
            required
            acceptNumbersOnly
            label="cardCode"
            placeholderText={t('loginInputs.cardCode')}
            errorState={errors.cardCode ? true : false}
            value={''}
          />
          <CustomInput
            register={register}
            required
            maxLength={4}
            acceptNumbersOnly
            label="pinCode"
            type="password"
            placeholderText={t('loginInputs.pinCode')}
            errorState={errors.pinCode ? true : false}
            value={''}
          />
          <div className="Login__checkboxWrapper">
            <CustomCheckbox
              defaultValue={true}
              register={register}
              required={false}
              setValue={setValue}
              label="rememberPersonalData"
            />
            <div className="Login__textWrapper">
              <span className={`Login__checkboxText${!errors.rememberPersonalData ? '' : 'Error'}`}>
                {t('login.rememberPersonalData.title')}
              </span>
            </div>
            <div
              className="Login__InfoIconWrapper"
              onClick={() =>
                openModalHandler(CustomModalType.HTML, HtmlContentType.REMEMBER_PERSONAL_DATA)
              }>
              <InfoIcon />
            </div>
          </div>
          <div className="Login__checkboxWrapper">
            <CustomCheckbox
              defaultValue={false}
              register={register}
              label="isDataPolicyAccepted"
              required={true}
              setValue={setValue}
              errorState={errors.isDataPolicyAccepted ? true : false}
            />
            <div className="Login__textWrapper">
              <span
                className={`Login__checkboxText${
                  !errors.isDataPolicyAccepted || watchDataPolicyAcceptedField ? '' : 'Error'
                }`}>
                {t('login.gdpr.text1')}
              </span>
              <span
                className={`Login__checkboxLinkText${
                  !errors.isDataPolicyAccepted || watchDataPolicyAcceptedField ? '' : 'Error'
                }`}
                onClick={() => openModalHandler(CustomModalType.PDF, FileType.DATA_POLICY)}>
                {t('login.gdpr.gdprText')}
              </span>
              <span
                className={`Login__checkboxText${
                  !errors.isDataPolicyAccepted || watchDataPolicyAcceptedField ? '' : 'Error'
                }`}>
                {t('login.gdpr.text2')}
              </span>
              <span
                className={`Login__checkboxLinkText${
                  !errors.isDataPolicyAccepted || watchDataPolicyAcceptedField ? '' : 'Error'
                }`}
                onClick={() =>
                  openModalHandler(CustomModalType.PDF, FileType.TERMS_AND_CONDITIONS)
                }>
                {t('login.gdpr.eulaText')}
              </span>
              <span
                className={`Login__checkboxText${
                  !errors.isDataPolicyAccepted || watchDataPolicyAcceptedField ? '' : 'Error'
                }`}>
                {t('login.gdpr.text3')}
              </span>
            </div>
          </div>

          <div className="Login__checkboxWrapper">
            <CustomCheckbox
              defaultValue={false}
              register={register}
              label="isEULAAccepted"
              required={true}
              setValue={setValue}
              errorState={errors.isEULAAccepted ? true : false}
            />
            <div className="Login__textWrapper">
              <span
                className={`Login__checkboxText${
                  !errors.isEULAAccepted || watchEULAAcceptedField ? '' : 'Error'
                }`}>
                {t('login.eula.text1')}
              </span>
              <span
                className={`Login__checkboxLinkText${
                  !errors.isEULAAccepted || watchEULAAcceptedField ? '' : 'Error'
                }`}
                onClick={() => openModalHandler(CustomModalType.PDF, FileType.DATA_POLICY)}>
                {t('login.eula.gdprText')}
              </span>
              <span
                className={`Login__checkboxText${
                  !errors.isEULAAccepted || watchEULAAcceptedField ? '' : 'Error'
                }`}>
                {t('login.eula.text2')}
              </span>
            </div>
          </div>
          <CustomButton text={t('login.loginButton')} />
        </form>
        <div className="Login__extraLicencesWrapper">
          <div className="Login__impressumWrapper">
            <div className="Login__textWrapperUnderlined">
              <a className="Login__impressumText" href={emailAddress}>
                {t('footer.bugReport')}
              </a>
            </div>
          </div>

          <div>
            <div
              className="Login__impressumWrapper"
              onClick={() => openModalHandler(CustomModalType.HTML, HtmlContentType.OPEN_SOURCE)}>
              <div className="Login__textWrapperUnderlined">
                <span
                  className={`Login__impressumText${!errors.rememberPersonalData ? '' : 'Error'}`}>
                  {t('openSource')}
                </span>
              </div>
            </div>

            <div
              className="Login__impressumWrapper"
              onClick={() => openModalHandler(CustomModalType.HTML, HtmlContentType.IMPRESSUM)}>
              <div className="Login__textWrapperUnderlined">
                <span
                  className={`Login__impressumText${!errors.rememberPersonalData ? '' : 'Error'}`}>
                  {t('impressum.title')}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        isVisible={isModalVisible}
        closeModalHandler={closeModalHandler}
        modalConfig={modalConfig}
      />
    </div>
  );
};

export default Login;
