import { FunctionComponent } from 'react';

interface InfoIconProps {}

const InfoIcon: FunctionComponent<InfoIconProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
      <path
        id="system-info-large"
        d="M7.5,6.545V9.818M7.5,6V5.182m0,8.318a6,6,0,1,0-6-6A6,6,0,0,0,7.5,13.5Z"
        transform="translate(-1 -1)"
        fill="none"
        stroke="#b2b2b2"
        strokeWidth="1"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default InfoIcon;
