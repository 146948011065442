import './PaymentFailedModal.scss';

import { Grid } from '@mui/material';
import { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PaymentFailedIcon } from '../../../assets/icons/payment-failed-icon.svg';
import CustomButton from '../../UI/CustomButton/CustomButton';
import Modal from '../../UI/Modal/Modal';

interface PaymentFailedModalProps {
  isVisible: boolean;
  closeModalHandler: () => void;
  transactionCode?: string;
}

const PaymentFailedModal: FunctionComponent<PaymentFailedModalProps> = memo(
  ({ isVisible, closeModalHandler, transactionCode }) => {
    const { t } = useTranslation();

    const handleConfirm = () => {
      closeModalHandler();
    };

    return (
      <Modal
        isVisible={isVisible}
        closeModalHandler={closeModalHandler}
        customClass="payment-failed-modal">
        <Grid container direction="column" spacing={2} className="payment-failed-modal-content">
          <Grid item className="modal-container">
            <PaymentFailedIcon />
            <p className="modal-header-text font-audi-extended-bold">
              {t('modals.failedTransactionHeader')}
            </p>
            {transactionCode && (
              <p className="modal-small-text font-audi-normal">{`${t(
                'modals.simplePayTransactionId'
              )}${transactionCode}`}</p>
            )}
            <p className="modal-small-text font-audi-normal">
              {t('modals.failedTransactionTipText')}
            </p>
            <CustomButton customFunction={handleConfirm} text={t('header.home')} />
          </Grid>
        </Grid>
      </Modal>
    );
  }
);

export default memo(PaymentFailedModal);
