export const languages = [
  {
    src: 'hungary-flag-icon.png',
    alt: 'hu',
    value: 'hu'
  },
  {
    src: 'germany-flag-icon.png',
    alt: 'de',
    value: 'de'
  }
];
