import { FunctionComponent } from 'react';
import './CustomButton.scss';

interface CustomButtonProps {
  text: string;
  customFunction?: () => void;
}

const CustomButton: FunctionComponent<CustomButtonProps> = ({
  text,
  customFunction
}: CustomButtonProps) => {
  return (
    <div className="CustomButton">
      <button
        className="CustomButton__button"
        type="submit"
        onClick={customFunction ? customFunction : undefined}>
        {text}
      </button>
    </div>
  );
};

export default CustomButton;
