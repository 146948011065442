import { useState } from 'react';
import { IconButton } from '@mui/material';
import { Fade as Hamburger } from 'hamburger-react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import DrawerMenuItem from './DrawerMenuItem/DrawerMenuItem';
import { menuItems, menuItemTypes } from '../../utils/menu.config';
import './CustomDrawer.scss';

const CustomDrawer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const { t } = useTranslation();

  const closeDrawer = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsOpen(false);
    }, 600);
  };

  const toggleDrawer = () => {
    if (!isOpen) {
      setIsOpen(true);
      setIsClosing(false);
    } else closeDrawer();
  };

  return (
    <div>
      <IconButton sx={{ p: 0 }} onClick={toggleDrawer}>
        <Hamburger
          size={24}
          direction="right"
          toggled={isOpen && !isClosing}
          color="#333333"
          easing="ease-in"
          duration={0.6}
        />
        <div className="menu-toggle-text font-audi-normal">
          {isOpen && !isClosing ? t('header.closeText') : t('header.openText')}{' '}
        </div>
      </IconButton>

      {isOpen &&
        createPortal(
          <div className={`${isClosing ? 'close' : ''} drawer-container`}>
            {menuItems.map((item, index) => (
              <div
                key={'menu-item-' + index}
                className={`menu-item ${index === 0 ? 'pt-2rem' : ''}
                  ${index === menuItems.length - 1 ? 'border-bottom-none' : ''}
                `}>
                <DrawerMenuItem
                  label={t(item.label)}
                  linkTo={item.linkTo}
                  isLogout={item.type === menuItemTypes.logout}
                  isExternal={item.type === menuItemTypes.external}
                  handleDrawerItemClick={toggleDrawer}
                />
              </div>
            ))}
          </div>,
          document.getElementById('drawer')!
        )}
    </div>
  );
};

export default CustomDrawer;
