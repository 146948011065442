import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { languages } from '../../utils/language.config';
import './LanguagePicker.scss';

const LanguagePicker = () => {
  const { i18n } = useTranslation();

  return (
    <div className="language-picker">
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        columnSpacing={1.5}>
        {languages.map((language, index) => (
          <Grid item key={index}>
            <div
              className={`flag-icon-container ${
                i18n.language !== language.value ? 'flag-icon-blurred' : ''
              }`}>
              <img
                className="flag-icon"
                src={require('../../assets/images/' + language.src)}
                alt={language.alt}
                onClick={() => {
                  if (i18n.language !== language.value) {
                    i18n.changeLanguage(language.value);
                    localStorage.setItem('lang', language.value);
                  }
                }}
              />
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default LanguagePicker;
