import { FunctionComponent } from 'react';
import Login from '../../components/Login/Login';
import SideImage from '../../assets/images/sidepicture.jpg';
import './LoginPage.scss';

interface LoginPageProps {}

const LoginPage: FunctionComponent<LoginPageProps> = () => {
  return (
    <div className="LoginPage">
      <div className="LoginPage__loginWrapper">
        <Login />
        <div className="LoginPage__sideImageWrapper">
          <img src={SideImage} className="LoginPage__sideImage" />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
