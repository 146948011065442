import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import CancelSmall from '../../../assets/icon-components/CancelSmall';
import Backdrop from '../Backdrop/Backdrop';
import './Modal.scss';

interface ModalProps {
  isVisible: boolean;
  closeModalHandler: () => void;
  modalClosing?: boolean;
  children?: ReactNode;
  customClass?: string;
}

const Modal: FunctionComponent<ModalProps> = ({
  isVisible,
  closeModalHandler,
  modalClosing,
  children,
  customClass
}) => {
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (modalClosing) closeHandler();
  }, [modalClosing]);

  const closeHandler = () => {
    setIsClosing(true);
    const timer = setTimeout(() => {
      closeModalHandler();
      setIsClosing(false);
    }, 500);
    return () => clearTimeout(timer);
  };

  return (
    <>
      <Backdrop isVisible={isVisible} closeModalHandler={closeHandler} isClosing={isClosing} />
      {isVisible &&
        createPortal(
          <div className={`Modal${isClosing ? 'Closing' : ''} ${customClass}`}>
            <div className="Modal__closeButtonWrapper" onClick={closeHandler}>
              <CancelSmall />
            </div>
            {children}
          </div>,
          document.getElementById('modal-root')!
        )}
    </>
  );
};

export default Modal;
