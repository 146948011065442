import { Grid } from '@mui/material';
import { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus-icon.svg';
import { priceOptions } from '../../../utils/price-options.config';
import Modal from '../../UI/Modal/Modal';
import './PriceOptionsModal.scss';

interface CustomModalProps {
  isVisible: boolean;
  closeModalHandler: (topUpBy?: number | 'custom' | null) => void;
}

const DeleteAccountModal: FunctionComponent<CustomModalProps> = memo(
  ({ isVisible, closeModalHandler }) => {
    const { t, i18n } = useTranslation();

    return (
      <Modal
        isVisible={isVisible}
        closeModalHandler={closeModalHandler}
        customClass="price-options-modal">
        <Grid container direction="column" spacing={2} className="price-options-modal-content">
          <Grid item className="modal-text-container">
            <div className="font-audi-extended-bold">
              <h3>{t('modals.priceOptionsTitle')}</h3>
            </div>
          </Grid>

          <Grid>
            <div
              className="simple-pay"
              onClick={() => {
                if (i18n.language === 'hu') {
                  window.open(
                    'https://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf',
                    '_blank'
                  );
                } else {
                  window.open(
                    'https://simplepartner.hu/PaymentService/Payment_information.pdf',
                    '_blank'
                  );
                }
              }}>
              <img
                src={require('../../../assets/images/simplepay-logo.png')}
                alt={t('modal.simplePayDoc')}
              />
            </div>
          </Grid>
          <Grid container spacing={3}>
            {priceOptions.map((option, index) => (
              <Grid item key={index} xs={6} md={3}>
                <div
                  className="card"
                  onClick={() => {
                    closeModalHandler(option);
                  }}>
                  <div className="icon-container">
                    <PlusIcon />
                  </div>
                  <div className="price">
                    {new Intl.NumberFormat('hu-HU', {
                      style: 'currency',
                      currency: 'HUF',
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0
                    }).format(option)}
                  </div>
                </div>
              </Grid>
            ))}
            <Grid item xs={6} md={3}>
              <div
                className="card"
                onClick={() => {
                  closeModalHandler('custom');
                }}>
                <div className="icon-container">
                  <PlusIcon />
                </div>
                <div className="price">{t('modals.customPrice')}</div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    );
  }
);

export default memo(DeleteAccountModal);
