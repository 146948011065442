/* eslint-disable import/no-anonymous-default-export */
export default {
  login: {
    loginHeader: 'Audi Gastro',
    loginText: 'Anmeldung',
    rememberPersonalData: {
      title: 'Meine Login-Daten merken',
      htmlContent:
        '<div class="font-audi-normal" style="padding: 0 20px"><p>Wenn Sie das Häkchen - nach erfolgreicher Anmeldung - abhaken, bleiben Sie 14 Tage lang auf diesem Gerät und Browser angemeldet, sodass Sie sich nicht erneut anmelden müssen. solange Sie auf den Ausmeldung Button nicht geklickt haben.</p></div>'
    },
    sessionExpired: 'Sitzung ist abgelaufen, bitte melden Sie sich erneut an!',
    gdpr: {
      text1: 'Ich habe gelesen',
      gdprText: 'Datenschutzerklärung',
      text2: ' und',
      eulaText: 'Bedienungsanleitung',
      text3: 'und ich habe ihren Inhalt zur Kenntnis genommen.'
    },
    eula: {
      text1:
        'Ich stimme dem zu meine personenbezogenen Daten im Zusammenhang mit der Aufladung des Guthabens zu verwalten',
      gdprText: 'Datenschutzerklärung',
      text2: 'zu'
    },
    loginButton: 'Anmeldung'
  },
  impressum: {
    title: 'Impressum',
    htmlContent:
      '<div class="font-audi-normal" style="padding: 0 20px"><h1 class="font-audi-extended-bold">AUDI HUNGARIA Zártkörűen Működő Részvénytársaság</h1><p>9027 Győr, Audi Hungária út 1.</p><p><strong>mailto:</strong> <a href="mailto:ah@audi.hu">ah@audi.hu</a></p><p><a href="https://www.audi.hu/" rel="noopener noreferrer" target="_blank">https://www.audi.hu/</a></p><p><strong>Handelsregisternummer:</strong> 08-10-001840</p><p><strong>Registrierungsbehörde:</strong> Handelsregistergericht des Landgerichts Győr</p><p><strong>Steuernummer:</strong> 23391475-2-08</p></div>'
  },
  openSource: 'OpenSource Lizenz',
  loginInputs: {
    personCode: 'Stammnummer',
    cardCode: 'Werksausweisnummer',
    pinCode: 'PIN'
  },
  header: {
    home: 'Home',
    hrPortal: 'HR-Mitarbeiterportal',
    termsOfUse: 'Bedienungsanleitung',
    logout: 'Abmelden',
    closeText: 'Schließen',
    openText: 'Menu'
  },
  footer: {
    deleteAccount: 'Mein Konto löschen',
    bugReport: 'Fehler melden',
    privacyNotice: 'Datenschutzerklärung'
  },
  modals: {
    deleteUserSuccess: 'Kontolöschung erfolgreich!',
    deleteUserFailed: 'Kontolöschung fehlgeschlagen! Bitte versuchen Sie es später erneut.',
    deleteAccountConfirmModalHeaderText: 'Möchten Sie Ihr Konto wirklich löschen?',
    customPrice: 'Individueller Betrag',
    priceOptionsTitle: 'Guthaben Aufladen',
    customPriceOptionTitle: 'Bitte geben Sie den Betrag ein, den Sie hochladen möchten:',
    customPriceOptionNotice: 'Höchstbetrag: 50 000 HUF',
    priceUploadConfirmTitle: 'Bestätigung Aufladung',
    priceUploadConfirmText: 'Betrag zum Aufladen',
    uploadButton: 'Aufladung',
    confirmButton: 'Bestätigen',
    pendingTransactionHeader: 'Transaktion wird bearbeitet',
    pendingTransactionText:
      'Wenn die Transaktion erfolgreich ist, wird der Betrag innerhalb von 1 Minute gutgeschrieben',
    successfulTransactionHeader: 'Erfolgreiche Zahlung, Transaktionsverarbeitung ist im Gange',
    successfulTransactionText:
      'Wenn die Transaktion erfolgreich ist, wird der Betrag innerhalb von 1 Minute gutgeschrieben',
    failedTransactionHeader: 'Aufladung fehlgeschlagen. Bitte versuchen Sie es erneut',
    failedTransactionTipText:
      'Bitte überprüfen Sie sorgfältig die Richtigkeit und Vollständigkeit Ihrer Angaben. Wenn eine Zahlung abgelehnt wurde, sollten Sie zunächst den Grund dafür herausfinden. Unter Umständen müssen Sie sich dazu mit Ihrer Bank bzw. Ihrem Kreditkartenunternehmen in Verbindung setzen.',
    cancelledTransactionHeader: 'Sie haben die Zahlungvorgang unterbrochen.',
    simplePayTransactionId: 'SimplePay-Transaktions-ID: ',
    timeoutTransactionText:
      'Sie haben die maximal zulässige Laufzeit für die Ausführung der Transaktion überschritten.'
  },
  home: {
    balanceText: 'Aktueller Saldo',
    uploadBalance: 'Hochladung',
    transactionHistory: 'Transaktionshistorie',
    menu: 'Speisekarte'
  },
  common: {
    yes: 'Ja',
    no: 'Nein',
    huf: 'HUF'
  },
  transactionHistoryPage: {
    title: 'Transaktionshistorie',
    filterAll: 'Alle',
    filterExpense: 'Einkäufe',
    filterIncome: 'Hochladung',
    noResult: 'Keine Ergebnisse gefunden!',
    vending: 'Verkauf',
    deposit: 'Hinterlegung'
  },
  menuPage: {
    menu: 'Speisekarte'
  },
  privacyNoticePage: {
    privacyNotice: 'Datenschutzerklärung'
  },
  termsOfUsePage: {
    termsOfUse: 'Bedienungsanleitung'
  },
  file: {
    fileNotFound: 'Datei nicht gefunden'
  },
  pdf: {
    previousPage: 'Vorherige',
    nextPage: 'Nächste',
    page: 'Seite'
  }
};
