import { Container } from '@mui/material';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MinusIcon } from '../../assets/icons/minus-icon.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus-icon.svg';
import { SnackbarContext } from '../../components/SnackbarAlert/SnackbarAlert';
import { TransactionTypeTranslationLookup, TransactionType } from '../../enums/transaction-type.enum';
import { IGetPersonTransactionsRequest } from '../../interfaces/requests/wallet/wallet.request';
import { ITransaction } from '../../interfaces/responses/wallet.response';
import { getPersonTransactions } from '../../services/wallet.service';
import './TransactionHistoryPage.scss';

type FilterOptionValueTypes = 'all' | 'expense' | 'income';

const TransactionHistoryPage = () => {
  const [selectedFilter, setSelectedFilter] = useState<FilterOptionValueTypes>('all');
  const [transactions, setTransactions] = useState<ITransaction[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);

  useEffect(() => {
    getData();
  }, [selectedFilter]);

  const getData = async () => {
    setIsLoading(true);
    const getPersonTransactionsRequest: IGetPersonTransactionsRequest = {
      days: 5,
      languageCode: i18n.language
    };

    const data = await getPersonTransactions(getPersonTransactionsRequest);

    if (data?.status?.code > 0 && !data.transactions) {
      setSnack({
        isError: true,
        open: true,
        message: data.status.message
      });
    }

    const transactions = data.transactions;

    if (selectedFilter === 'all') {
      setTransactions(transactions);
    }
    if (selectedFilter === 'income') {
      const filteredData = transactions.filter((transaction: ITransaction) => {
        return transaction.balanceChange > 0;
      });
      setTransactions(filteredData);
    }
    if (selectedFilter === 'expense') {
      const filteredData = transactions.filter((transaction: ITransaction) => {
        return transaction.balanceChange < 0;
      });
      setTransactions(filteredData);
    }
    
    setIsLoading(false);
  };

  return (
    <Container>
      <div className="transactions-page">
        <h2 className="font-audi-extended-bold header-text">{t('transactionHistoryPage.title')}</h2>

        <div className="transaction-history-container">
          <div className="filter-options-container">
            <div
              className={`filter-option font-audi-normal ${selectedFilter === 'all' && ' selected'}`}
              onClick={() => setSelectedFilter('all')}>
              {t('transactionHistoryPage.filterAll')}
            </div>
            <div
              className={`filter-option font-audi-normal ${selectedFilter === 'expense' && ' selected'}`}
              onClick={() => setSelectedFilter('expense')}>
              {t('transactionHistoryPage.filterExpense')}
            </div>
            <div
              className={`filter-option font-audi-normal ${selectedFilter === 'income' && ' selected'}`}
              onClick={() => setSelectedFilter('income')}>
              {t('transactionHistoryPage.filterIncome')}
            </div>
          </div>
        </div>

        <div className="transaction-list-container">
          {transactions?.length ? (
            !isLoading &&
            transactions.map((transaction, index) => (
              <div key={index} className="list-item">
                <div className="list-item-left">
                  <div className="icon-container">
                    {transaction.balanceChange < 0 ? <MinusIcon /> : <PlusIcon />}
                  </div>
                  <div>
                    <div className="transaction-title font-audi-bold">{t(TransactionTypeTranslationLookup[transaction.type as TransactionType])}</div>
                    <div className="transaction-date font-audi-normal">
                      {moment(transaction.dateTime).format('YYYY.MM.DD HH:mm')}
                    </div>
                  </div>
                </div>
                <div className="transaction-amount font-audi-bold">
                  {transaction.balanceChange < 0 ? '' : '+'}
                  {new Intl.NumberFormat('hu-HU', {
                    style: 'currency',
                    currency: 'HUF',
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0
                  }).format(transaction.balanceChange)}
                </div>
              </div>
            ))
          ) : (
            <div className="no-record">{t('transactionHistoryPage.noResult')}</div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default TransactionHistoryPage;
