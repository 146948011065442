import { Grid } from '@mui/material';
import { FunctionComponent, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TrashIconBig } from '../../../assets/icons/trash-icon-big.svg';
import CustomButton from '../../UI/CustomButton/CustomButton';
import Modal from '../../UI/Modal/Modal';
import './DeleteAccountModal.scss';

interface CustomModalProps {
  isVisible: boolean;
  closeModalHandler: () => void;
  confirmModalHandler: () => void;
}

const DeleteAccountModal: FunctionComponent<CustomModalProps> = memo(
  ({ isVisible, closeModalHandler, confirmModalHandler }) => {
    const { t } = useTranslation();
    const [modalClosing, setModalClosing] = useState(false);

    const modalClose = () => {
      setModalClosing(true);
      setTimeout(() => {
        setModalClosing(false);
      }, 500);
    };

    return (
      <Modal
        isVisible={isVisible}
        closeModalHandler={closeModalHandler}
        modalClosing={modalClosing}
        customClass="delete-account-modal">
        <Grid container direction="column" spacing={2} className="delete-account-modal-content">
          <Grid item className="delete-text-container">
            <div className="icon-container">
              <TrashIconBig />
            </div>
            <div className="font-audi-extended-bold">
              <h3>{t('modals.deleteAccountConfirmModalHeaderText')}</h3>
            </div>
          </Grid>
          <Grid item>
            <div className="confirm-buttons">
              <div className="button-transparent-background">
                <CustomButton text={t('common.yes')} customFunction={confirmModalHandler} />
              </div>
              <CustomButton text={t('common.no')} customFunction={modalClose} />
            </div>
          </Grid>
        </Grid>
      </Modal>
    );
  }
);

export default memo(DeleteAccountModal);
