import { useTranslation } from 'react-i18next';
import './FakeInputNDigits.scss';

interface FakeInputNDigitsProps {
  value: string;
  dotTo?: number[];
  digits: number;
}

const FakeInputNDigits = ({ value, dotTo = [-1], digits }: FakeInputNDigitsProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="fake-input-container">
        {Array.from({ length: digits }, (_, index) => {
          const isDot = dotTo.includes(index);
          return (
            <>
              {isDot && <p className="dot">.</p>}
              <div className="digit-container">
                <p key={index} className="digit">
                  {value[value.length - digits + index] || ''}
                </p>
              </div>
            </>
          );
        })}
        <p className="currency-text">{t('common.huf')}</p>
      </div>
    </>
  );
};

export default FakeInputNDigits;
