import { FunctionComponent } from 'react';

const CancelSmall: FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.907"
      height="11.907"
      viewBox="0 0 11.907 11.907">
      <path
        id="cancel-small"
        d="M5.9,17.1,17.1,5.9M5.9,5.9,17.1,17.1"
        transform="translate(-5.546 -5.546)"
        fill="none"
        stroke="#000"
        strokeWidth="1"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default CancelSmall;
