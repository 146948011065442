import { FunctionComponent } from 'react';

const TickIcon: FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 76.887 55.552"
      xmlSpace="preserve">
      <g id="Layer_15">
        <polygon
          points="34.373,49.541 76.887,5.889 70.739,0 28.426,43.458 6.078,20.632 0,26.585 22.488,49.557 
            22.488,49.557 28.517,55.552 28.426,55.467 28.517,55.552 34.373,49.541 	"
        />
      </g>
      <g id="Layer_1"></g>
    </svg>
  );
};

export default TickIcon;
