import { Container } from '@mui/system';
import { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import HomePage from '../pages/HomePage/HomePage';
import LoginPage from '../pages/LoginPage/LoginPage';
import MenuPage from '../pages/MenuPage/MenuPage';
import PrivacyNoticePage from '../pages/PrivacyNoticePage/PrivacyNoticePage';
import TermsOfUsePage from '../pages/TermsOfUsePage/TermsOfUsePage';
import TransactionHistoryPage from '../pages/TransactionHistoryPage/TransactionHistoryPage';
import { PrivateRoutes } from './PrivateRoutes';
import './Router.scss';

interface RouterProps {
  isLoggedIn: boolean;
}

const Router: FunctionComponent<RouterProps> = ({ isLoggedIn }) => {
  const location = useLocation();
  const { i18n } = useTranslation();

  const setLanguageIfExists = () => {
    const language = localStorage.getItem('lang');
    if (language) {
      i18n.changeLanguage(language);
    }
  };

  useEffect(() => {
    setLanguageIfExists();
  }, []);

  return (
    <>
      {isLoggedIn && localStorage.getItem('accessToken') && <Header />}
      <TransitionGroup>
        <CSSTransition key={location.key} classNames="page" timeout={300}>
          <div>
            <Container
              className={`${
                isLoggedIn && localStorage.getItem('accessToken') ? 'content' : ''
              } page-content-container`}>
              <Routes>
                <>
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/" element={<PrivateRoutes />}>
                    <Route path="/home" element={<HomePage />} />
                    <Route path="/menu" element={<MenuPage />} />
                    <Route path="/privacy-notice" element={<PrivacyNoticePage />} />
                    <Route path="/user-guide" element={<TermsOfUsePage />} />
                    <Route path="/transaction-history" element={<TransactionHistoryPage />} />
                    <Route path="/" element={<Navigate to="/home" />} />
                  </Route>
                </>
              </Routes>
            </Container>
            {isLoggedIn && <Footer />}
          </div>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
};

export default Router;
