import { Grid } from '@mui/material';
import { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PaymentFailedIcon } from '../../../assets/icons/payment-failed-icon.svg';
import CustomButton from '../../UI/CustomButton/CustomButton';
import Modal from '../../UI/Modal/Modal';
import './PaymentCancelledModal.scss';

interface PaymentFailedModalProps {
  isVisible: boolean;
  closeModalHandler: () => void;
}

const PaymentCancelledModal: FunctionComponent<PaymentFailedModalProps> = memo(
  ({ isVisible, closeModalHandler }) => {
    const { t } = useTranslation();

    const handleConfirm = () => {
      closeModalHandler();
    };

    return (
      <Modal
        isVisible={isVisible}
        closeModalHandler={closeModalHandler}
        customClass="payment-cancelled-modal">
        <Grid container direction="column" spacing={2} className="payment-cancelled-modal-content">
          <Grid item className="modal-container">
            <PaymentFailedIcon />
            <p className="modal-header-text font-audi-extended-bold">
              {t('modals.cancelledTransactionHeader')}
            </p>
            <CustomButton customFunction={handleConfirm} text={t('header.home')} />
          </Grid>
        </Grid>
      </Modal>
    );
  }
);

export default memo(PaymentCancelledModal);
