import { Close } from '@mui/icons-material';
import { Alert, IconButton, Snackbar } from '@mui/material';
import React, { createContext, Fragment, useContext } from 'react';

export interface ISnackbarProps {
  open: boolean;
  message: string;
  isError: boolean;
}

export const SnackbarContext = createContext({
  snack: {
    open: false,
    message: '',
    isError: true
  },
  setSnack: (snackbarProps: ISnackbarProps) => {}
});

const SnackbarAlert = () => {
  const { snack, setSnack } = useContext(SnackbarContext);

  const autoHideDuration = 3000;

  const onClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnack({
      ...snack,
      open: false,
      message: ''
    });
  };

  const snackBarAction = (
    <Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
        <Close fontSize="small" />
      </IconButton>
    </Fragment>
  );

  return (
    <>
      {snack.isError ? (
        <Snackbar
          open={snack.open}
          autoHideDuration={autoHideDuration}
          action={snackBarAction}
          onClose={onClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert onClose={onClose} severity="error" sx={{ width: '100%' }}>
            {snack.message}
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          open={snack.open}
          autoHideDuration={autoHideDuration}
          action={snackBarAction}
          onClose={onClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert onClose={onClose} severity="success" sx={{ width: '100%' }}>
            {snack.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default SnackbarAlert;
