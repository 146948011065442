import { ApiClient } from '../apiClient/apiClient';
import { IGetPersonTransactionsRequest } from '../interfaces/requests/wallet/wallet.request';

const api = ApiClient.getInstance();

const getCreditInformation = async () => {
  const data = await api.get(`wallet/creditInformation`).then((res) => {
    return res.data.wallets[0].balance;
  }).catch((error) => {
    return error.response.data
  });

  return data;
};

const getPersonTransactions = async (
  getPersonTransactionsRequest: IGetPersonTransactionsRequest
) => {
  const data = await api
    .get('wallet/personTransactions', getPersonTransactionsRequest)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data
    });

  return data;
};

export { getCreditInformation, getPersonTransactions };
