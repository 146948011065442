import { FunctionComponent } from 'react';

interface AudiLogoProps {}

const AudiLogo: FunctionComponent<AudiLogoProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="69.334"
      height="24"
      viewBox="0 0 69.334 24">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_10" data-name="Rectangle 10" width="69.334" height="24" />
        </clipPath>
      </defs>
      <g id="logo_black" data-name="logo black" transform="translate(0 0)">
        <g id="Group_1" data-name="Group 1" transform="translate(0 0)" clipPath="url(#clip-path)">
          <path
            id="Path_1"
            data-name="Path 1"
            d="M57.334,0a11.941,11.941,0,0,0-7.556,2.686,11.969,11.969,0,0,0-15.112,0,11.969,11.969,0,0,0-15.111,0,12,12,0,1,0,0,18.627,11.969,11.969,0,0,0,15.111,0,11.969,11.969,0,0,0,15.112,0A12,12,0,1,0,57.334,0M49.778,17.437a9.283,9.283,0,0,1,0-10.873,9.283,9.283,0,0,1,0,10.873m-15.112,0a9.284,9.284,0,0,1,0-10.873,9.283,9.283,0,0,1,0,10.873m-15.111,0a9.283,9.283,0,0,1,0-10.873,9.283,9.283,0,0,1,0,10.873M2.686,12A9.307,9.307,0,0,1,17.665,4.614a11.961,11.961,0,0,0,0,14.773A9.307,9.307,0,0,1,2.686,12m18.761,7.387a11.961,11.961,0,0,0,0-14.773,9.286,9.286,0,0,1,11.329,0,11.962,11.962,0,0,0,0,14.773,9.287,9.287,0,0,1-11.329,0m15.111,0a11.961,11.961,0,0,0,0-14.773,9.286,9.286,0,0,1,11.329,0,11.961,11.961,0,0,0,0,14.773,9.287,9.287,0,0,1-11.329,0m20.776,1.928a9.266,9.266,0,0,1-5.665-1.928,11.961,11.961,0,0,0,0-14.773,9.312,9.312,0,1,1,5.665,16.7"
            transform="translate(0 0)"
          />
        </g>
      </g>
    </svg>
  );
};

export default AudiLogo;
